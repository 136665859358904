<div
  [class.container]="isVerticalLayout$ | async"
  class="toolbar text-default w-full px-6 flex items-center">
  <button
    (click)="openSidenav()"
    [class.hidden]="isDesktop$ | async"
    mat-icon-button
    type="button">
    <mat-icon svgIcon="mat:menu"></mat-icon>
  </button>

  <a
    *ngIf="isVerticalLayout$ | async"
    [routerLink]="['/']"
    class="ltr:mr-4 rtl:ml-4 flex items-center">
    <img
      alt="Logo"
      class="w-8 select-none"
      src="../../../../assets/img/logo/logo.svg" />
    <h1
      [class.hidden]="isDesktop$ | async"
      class="text-2xl font-bold tracking-wide ltr:pl-4 rtl:pr-4 m-0 select-none">
      {{ title$ | async }}
    </h1>
  </a>

  <div
    *ngIf="(isVerticalLayout$ | async) && (isNavbarInToolbar$ | async)"
    [class.hidden]="!(isDesktop$ | async)"
    class="px-6 flex-none flex items-center">
    <vex-navigation-item
      *ngFor="let item of navigationItems$ | async"
      [item]="item"></vex-navigation-item>
  </div>

  <span class="flex-1"></span>

  <!-- Resumen del Sueño -->
  <div class="hidden md:flex items-center mr-4 rounded-full px-4 py-2 sleep-summary">
    <mat-icon class="text-purple-500 mr-2" svgIcon="mat:bedtime"></mat-icon>
    <div class="text-sm">
      <span class="font-medium">{{sleepSummary.averageHours | number:'1.1-1'}}h</span>
      <span class="text-secondary mx-1">•</span>
      <span [class.text-green-500]="sleepSummary.trend.startsWith('+')"
            [class.text-red-500]="!sleepSummary.trend.startsWith('+')">
        {{sleepSummary.trend}}
      </span>
    </div>
  </div>

  <!-- Estado del Dispositivo -->
  <button mat-icon-button class="mr-4" [matTooltip]="deviceTooltip" [class.text-green-500]="isDeviceConnected">
    <mat-icon [svgIcon]="isDeviceConnected ? 'mat:bluetooth_connected' : 'mat:bluetooth_disabled'"></mat-icon>
  </button>

  <div class="-mx-1 flex items-center">
    <!-- Consejos del Día -->
    <div class="px-1">
      <button [matMenuTriggerFor]="tipsMenu" mat-icon-button type="button" matTooltip="Consejos del día">
        <mat-icon class="text-purple-500" svgIcon="mat:tips_and_updates"></mat-icon>
      </button>
    </div>

    <!-- Notificaciones -->
    <div class="px-1">
      <vex-toolbar-notifications></vex-toolbar-notifications>
    </div>

    <!-- Panel Rápido -->
    <div class="px-1">
      <button (click)="openQuickpanel()" mat-icon-button type="button">
        <mat-icon class="text-purple-500" svgIcon="mat:bookmarks"></mat-icon>
      </button>
    </div>

    <!-- Usuario -->
    <div *ngIf="userVisible$ | async" class="px-1">
      <vex-toolbar-user></vex-toolbar-user>
    </div>

    <!-- Menú de Consejos -->
    <mat-menu #tipsMenu="matMenu" class="tips-menu">
      <div class="py-2 px-3 border-b">
        <h3 class="m-0 font-bold">Consejos para mejor descanso</h3>
      </div>
      <div class="p-4">
        <div *ngFor="let tip of dailyTips" class="tip-card p-3 rounded-lg mb-3 last:mb-0">
          <div class="flex items-center mb-2">
            <mat-icon class="text-purple-500 mr-2" [svgIcon]="'mat:' + tip.icon"></mat-icon>
            <span class="font-medium">{{tip.title}}</span>
          </div>
          <p class="text-sm text-secondary m-0">{{tip.description}}</p>
        </div>
      </div>
    </mat-menu>
  </div>
</div>

<vex-navigation
  *ngIf="(isVerticalLayout$ | async) && (isNavbarBelowToolbar$ | async)"
  [class.hidden]="!(isDesktop$ | async)"></vex-navigation>
