import { inject, Injectable } from '@angular/core';
import { HttpInterceptorFn } from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { AuthService } from '../service/auth.service';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const oauthService: AuthService = inject(AuthService)
  if (req.url.includes('identitytoolkit.googleapis.com')) {
    return next(req);
  }
  let newRequest = req.clone()
  if (oauthService.getToken() != null) {
    newRequest = req.clone({
      headers: req.headers.set('Authorization', 'Bearer '+oauthService.getToken())
    })
  }

  return next(newRequest).pipe(
    catchError( err => {
      if (err.status === 401){
        oauthService.logout()
      }
      throw err;
    })
  )
};
