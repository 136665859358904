import { Role } from '../../gosleep/admin/auth-control/config/permissions.config';

export interface UserPermission {
  name: string;
  description?: string;
}

export interface Company {
  id: string;
  name: string;
  contractId: string;
}

export class User {
  constructor(
    public id: string = '',
    public email: string = '',
    public emailVerified: boolean = false,
    public signInProvider: string = '',
    public role: Role = 'client',
    public name: string = '',
    public lastName: string = '',
    public photoURL: string = '',
    public permissions: string[] = [],
    public contractId: string = '',
    public clientId: string = '',
    public status: 'active' | 'inactive' = 'active',
    public company?: Company
  ) {}

  static fromJson(json: any): User {
    return new User(
      json.id || '',
      json.email || '',
      json.emailVerified || false,
      json.signInProvider || '',
      json.role || 'client',
      json.name || '',
      json.lastName || '',
      json.photoURL || '',
      json.permissions || [],
      json.contractId || '',
      json.clientId || '',
      json.status || 'active',
      json.company ? {
        id: json.company.id || '',
        name: json.company.name || '',
        contractId: json.company.contractId || ''
      } : undefined
    );
  }

  hasPermission(permission: string): boolean {
    return this.permissions.includes(permission) || this.role === 'admin';
  }

  hasRole(roles: Role[]): boolean {
    return roles.includes(this.role);
  }
}
