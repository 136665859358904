import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, tap, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { User } from '../model/User';

interface AuthResponse {
  idToken: string;
  email: string;
  refreshToken: string;
  expiresIn: string;
  localId: string;
  registered?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly API_URL = 'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=' + environment.KEY_PROJECT_WEB;
  private token: string | null = null;
  private authStatus = new BehaviorSubject<boolean>(false);
  private currentUserSubject: BehaviorSubject<User | null>;
  public currentUser$: Observable<User | null>;

  constructor(private http: HttpClient) {
    const storedUser = localStorage.getItem('currentUser');
    this.currentUserSubject = new BehaviorSubject<User | null>(
      storedUser ? User.fromJson(JSON.parse(storedUser)) : null
    );
    this.currentUser$ = this.currentUserSubject.asObservable();
  }

  private getInfoUserContract = (UUID: string): Observable<User> => {
    const path = environment.API_BASE_ENDPOINT + '/api/sync/get/user/' + UUID;
    return this.http.get<User>(path).pipe(
      map(userData => User.fromJson(userData))
    );
  };

  getAuthStatus(): Observable<boolean> {
    return this.authStatus.asObservable();
  }

  getCurrentUser(): User | null {
    return this.currentUserSubject.value;
  }

  hasPermission(permission: string): boolean {
    return this.getCurrentUser()?.permissions?.some(p => p === permission) || false;
  }

  hasRole(role: string): boolean {
    const user = this.getCurrentUser();
    return user?.role === role;
  }

  hasAnyRole(roles: string[]): boolean {
    const user = this.getCurrentUser();
    return user ? roles.includes(user.role) : false;
  }

  login(email: string, password: string): Observable<User> {
    return this.http
      .post<AuthResponse>(this.API_URL, {
        email,
        password,
        returnSecureToken: true
      })
      .pipe(
        switchMap(response => {
          this.handleAuthentication(response);
          const decodedToken = this.decodeToken(response.idToken);
          return this.getInfoUserContract(decodedToken.user_id);
        }),
        tap(user => {
          this.currentUserSubject.next(user);
          localStorage.setItem('currentUser', JSON.stringify(user));
        }),
        catchError(this.handleError)
      );
  }

  handleAuthentication(response: AuthResponse): void {
    const expirationDate = new Date(new Date().getTime() + +response.expiresIn * 1000);
    localStorage.setItem('token', response.idToken);
    localStorage.setItem('expirationDate', expirationDate.toISOString());
    this.token = response.idToken;
    this.authStatus.next(true);
  }

  handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = 'An error occurred!';
    if (error.error && error.error.error) {
      switch (error.error.error.message) {
        case 'EMAIL_NOT_FOUND':
          errorMessage = 'Email not found.';
          break;
        case 'INVALID_PASSWORD':
          errorMessage = 'Invalid password.';
          break;
        case 'USER_DISABLED':
          errorMessage = 'User account has been disabled.';
          break;
        default:
          errorMessage = 'Authentication failed.';
      }
    }
    return throwError(() => new Error(errorMessage));
  }

  logout(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('currentUser');
    this.token = null;
    this.currentUserSubject.next(null);
    this.authStatus.next(false);
  }

  isAuthenticated(): boolean {
    return !!this.getCurrentUser();
  }

  getToken(): string | null {
    return localStorage.getItem('token');
  }

  getClientId(): string | null {
    const user = this.getCurrentUser();
    return user?.clientId || null;
  }

  getRole(): string | null {
    const user = this.getCurrentUser();
    return user?.role || null;
  }

  getContractId(): string | null {
    const user = this.getCurrentUser();
    return user?.contractId || null;
  }

  getUser(): User | null {
    const token = this.getToken();
    if (!token) {
      return null;
    }

    try {
      const payload = this.decodeToken(token);
      return new User(
        payload.sub || payload.user_id,
        payload.email,
        payload.email_verified || false,
        payload.firebase?.sign_in_provider || ''
      );
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  }

  private decodeToken(token: string): any {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      return JSON.parse(window.atob(base64));
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  }
}
