import { Injectable } from '@angular/core';
import { VexLayoutService } from '@vex/services/vex-layout.service';
import { NavigationItem, NavigationLink, NavigationDropdown } from './navigation-item.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from '../service/auth.service';
import navigationConfig from './navigation.config.json';
import { NavigationConfig, NavigationSection, NavigationConfigItem } from './navigation-config.interface';
import { User } from '../model/User';

@Injectable({
  providedIn: 'root'
})
export class NavigationLoaderService {
  private readonly _items = new BehaviorSubject<NavigationItem[]>([]);

  get items$(): Observable<NavigationItem[]> {
    return this._items.asObservable();
  }

  constructor(
    private readonly layoutService: VexLayoutService,
    private readonly authService: AuthService
  ) {
    this.loadNavigation(this.authService.getCurrentUser());
    this.authService.currentUser$.subscribe((user) => {
      this.loadNavigation(user);
    });
  }

  private hasAccess(item: NavigationConfigItem | NavigationSection): boolean {
    if (!item.permissions && !item.roles) {
      return true;
    }

    const hasPermission = !item.permissions || item.permissions.every((permission: string) =>
      this.authService.hasPermission(permission)
    );

    const hasRole = !item.roles || this.authService.hasAnyRole(item.roles);

    return hasPermission && hasRole;
  }

  private processNavigationItem(item: NavigationSection | NavigationConfigItem): NavigationItem | null {
    if (!this.hasAccess(item)) {
      return null;
    }

    if (item.type === 'link') {
      if (item.route === 'configpanel') {
        return {
          ...item,
          type: 'link' as const,
          route: () => this.layoutService.openConfigpanel()
        };
      }
      return {
        ...item,
        type: 'link' as const
      };
    }

    if (item.type === 'subheading' && 'items' in item) {
      const children = item.items
        .map((child: NavigationConfigItem) => {
          if (child.type === 'link') {
            return this.processNavigationItem(child);
          }
          return null;
        })
        .filter((child): child is NavigationLink => child !== null);

      if (children.length === 0) {
        return null;
      }

      return {
        type: 'subheading' as const,
        label: item.label,
        children
      };
    }

    return null;
  }

  loadNavigation(user: User | null): void {
    const config = navigationConfig as NavigationConfig;
    const { navigation } = config;
    const items: NavigationItem[] = [];

    // Process dashboard section
    const dashboard = this.processNavigationItem(navigation.dashboard);
    if (dashboard) {
      items.push(dashboard);
    }

    // Process apps section
    const apps = this.processNavigationItem(navigation.apps);
    if (apps) {
      items.push(apps);
    }

    // Process admin section
    const admin = this.processNavigationItem(navigation.admin);
    if (admin) {
      items.push(admin);
    }

    // Process configuration
    const configuration = this.processNavigationItem(navigation.configuration);
    if (configuration) {
      items.push(configuration);
    }

    this._items.next(items);
  }
}
